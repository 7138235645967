import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Years',
    // component: () => import('@/views/YearsView.vue')
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/2023',
    name: '2023',
    component: () => import('@/views/RedirectView.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/profile/account',
    name: 'Profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('@/views/NotificationView.vue')
  },
  {
    path: '/subjects',
    name: 'Disciplinas',
    component: () => import('@/views/SubjectsView.vue')
  },
  {
    path: '/aula/:idaula',
    name: 'Aula',
    component: () => import('@/views/AulaView.vue')
  },
  {
    path: '/avaliacoes',
    name: 'Avaliações',
    component: () => import('@/views/AvaliacoesView.vue')
  },
  {
    path: '/avaliacao/:id_avaliacao',
    name: 'Avaliação',
    component: () => import('@/views/AvaliacaoView.vue')
  },
  {
    path: '/password-reset/:tokenPass?',
    name: 'Reset Password',
    component: () => import('@/views/PasswordResetView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
    
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 100
      }
    } else {
      return { x: 0, y: 0 };
    }
  },
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0) // Isso fará a rolagem para o topo da página
  next()
})
export default router
